<template>
  <div class="blockInfo">
    <span v-if="title" class="bockTitle">{{ title }}</span>
    <date-picker
      :disabled="disabled"
      type="date"
      range
      v-model="selectedDateRange"
      @change="updateValue"
      :clearable="true"
      format="YYYY-MM-DD"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "BlockInfoDatePicker",
  components: { DatePicker },
  props: {
    title: String,
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDateRange: [], 
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedDateRange = Array.isArray(newVal) && newVal.length > 0
          ? newVal.map(date => (date ? new Date(date) : null))
          : [new Date()]; 
      },
      immediate: true,
    },
  },
  methods: {
    updateValue(dates) {
      let dateRangeUTC;
      if (Array.isArray(dates)) {
        if (dates.length === 0) {
          dateRangeUTC = [new Date().toISOString()];
        } else if (dates.length === 1 || (dates[1] === null && dates[0] !== null)) {
          dateRangeUTC = [
            dates[0] ? new Date(dates[0].getTime() - dates[0].getTimezoneOffset() * 60000).toISOString() : null
          ];
        } else if (dates.length === 2 && dates[0] !== null && dates[1] !== null) {
          dateRangeUTC = dates.map(date => 
            date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString() : null
          );
        } else {
          dateRangeUTC = [new Date().toISOString()];
        }
      } else {
        dateRangeUTC = [new Date().toISOString()];
      }
      this.$emit("input", dateRangeUTC);
    },
  },
};
</script>

<style scoped>
.bockTitle {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
</style>